import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import Page from "../../components/Layout/Page";
import CasestudiesCarousel from "../../components/CaseStudies";
import Customers from "../../components/Customers";
import Container from "../../components/Layout/Container";
import BtnFilled from "../../components/reusable/BtnFilled";
import ContactBlock from "../../components/ContactBlock";
import Seo from "../../components/reusable/Seo";
const AiPage = () => {
  return (
    <Page className="solutions ai-ml">
      <Seo
        title="Artificial Intelligence & Machine Learning"
        path="solutions/artificial-intelligence-and-machine-learning"
        description="Use cutting edge AI-ML technologies, models and solution accelerators to solve complex business problems. End-to-End AI-ML powered solutions for mission critical
        applications at scale."
      />
      <section className="hero">
        <Container>
          <h1>
            Enhance customer experience &amp; business value with{" "}
            <i>Intelligent Solutions</i>
          </h1>
          <BtnFilled to="/contact-us" color="blue">
            Get in Touch
          </BtnFilled>
        </Container>
      </section>
      <section className="overview">
        <Container>
          <p className="intro">
            <b>
              End-to-End Delivery of AI-ML powered solutions for mission
              critical applications at scale
            </b>
          </p>
          <p>
            AI-ML solution implementation does not happen in isolation. They
            require a strong cloud infrastructure and data engineering
            foundation to build, train and deploy models at scale. Inviz
            provides end-to-end AI-ML solutions, including Data and Cloud
            expertise.
          </p>
        </Container>
      </section>
      <section className="accelerators" id="accelerators_">
        <Container>
          <h2>
            <b>Platforms &amp; Accelerators</b>
          </h2>
          <div className="items">
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/aiml--product-discovery-platform.png"
                    alt="Product Discovery Platform - Inviz AI"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="none"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Product Discovery Platform</b>
                  </h3>
                  <p>
                    End-to-end retail/ecommerce solution to address the evolving
                    customer expectations and enhance product discovery
                    experience. Improve relevancy with features such as
                    intelligent search, recommendation, dynamic filtering,
                    self-learning to rank, searchandizing and automated
                    labelling.{" "}
                  </p>
                  <p>
                    With our click-stream solution you have complete flexibility
                    to collect, structure, process click-stream data depending
                    on your business requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/aiml--knowledge-mgmt.png"
                    alt="Intelligent Knowledge Management Platform - Inviz AI"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="none"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Intelligent Knowledge Management Platform</b>
                  </h3>
                  <p>
                    With our Intelligent KM Platform you automate document
                    driven processes for compelling customer experience. It can
                    understand and extract unstructured data from pdf, email,
                    images or other formats.{" "}
                  </p>
                  <p>
                    Provides you with a single view of data by combining
                    structure and unstructured data, enabling AI-powered
                    document classification use case and automate business
                    processes.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/aiml--ml-ops-platform.png"
                    alt="MLOps Platform -  Inviz AI"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="none"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>MLOps Platform</b>
                  </h3>
                  <p>
                    Code makes up only a small fraction of real-world AI-ML
                    systems. Our MLOps Platform automates the end-to-end AI-ML
                    workflow to operationalize ML models. With built-in feature
                    store and model lifecycle management you can reduce
                    duplication of effort and build ML models with speed.
                  </p>
                  <p>
                    Must have features such as centralized repository,
                    standardized definition, versioning and monitoring work out
                    of the box.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="case-studies">
        <Container>
          <h2>
            <b>Case Studies</b>
          </h2>
          <CasestudiesCarousel />
        </Container>
      </section>
      <section className="solutions">
        <Container>
          <h2>
            <b>Solutions</b>
          </h2>
          <div className="items">
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/graphics/solutions-vectors_aiml--computer-vision.png"
                    alt="AI and ML - Computer Vision powered Identity and Quality Control"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>
                      Computer Vision{" "}
                      {/* powered Identity and Quality Control */}
                    </b>
                  </h3>
                  <p>
                    Visual Search &#183; Face Recognition &#183; Video/Image
                    Analytics
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/graphics/solutions-vectors_aiml--conversational-ai.png"
                    alt="AI and ML - Conversational AI"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Natural Language Processing</b>
                  </h3>
                  <p>
                    Information extraction &#183; Chatbots &#183; Smart
                    Assistants
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/graphics/solutions-vectors_aiml--information-extraction.png"
                    alt="AI and ML - Information extraction and analysis"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Search</b>
                  </h3>
                  <p>Ecommerce Search &#183; Smart Knowledge Base</p>
                </div>
              </div>
            </div>

            <div className="item">
              <div>
                <figure>
                  <StaticImage
                    src="../../images/solutions/graphics/solutions-vectors_aiml--intelligent-platforms.png"
                    alt="AI and ML - Intelligent Platforms"
                    layout="fullWidth"
                    loading="lazy"
                    placeholder="blurred"
                  />
                </figure>
                <div className="text">
                  <h3>
                    <b>Intelligent Accelerators</b>
                  </h3>
                  <p>
                    Personalization &#183; Recommendation &#183; Behavioral
                    Analytics
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <ContactBlock>
        Talk to an AI-ML expert to find the right solution for your use case
      </ContactBlock>
      <section className="clients">
        <Container>
          <h2>
            <b>Our Customers</b>
          </h2>
          <Customers />
        </Container>
      </section>
    </Page>
  );
};

export default AiPage;
